* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

html {
  background-image: url("./assets/backgroundImage.jpeg") no-repeat center center
    fixed;
  background-size: cover;
}
